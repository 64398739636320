import React from 'react';
import { useQuery, gql } from "@apollo/client";
import Schedule from './Schedule';
import styles from './style.module.css'

export default function OrderScheduler({orderId, referrer}) {

    const { loading, error, data } = useQuery(ORDER_SCHEDULE, {
        variables: { orderId: orderId },
        fetchPolicy: 'network-only',
    });

    if (loading) {
        return(
            <div className={styles.orderScheduler}>
                <h1>Shipping schedule</h1>
                <p>Loading ...</p>
            </div>
        );
    }

    if (error) {
        return(
            <div className={styles.orderScheduler}>
                <h1>Shipping schedule</h1>
                <p>Unable to load a schedule for this order.</p>
            </div>
        );
    }

    return(
        <div className={styles.orderScheduler}>
            <h1>Shipping schedule</h1>
            <Schedule orderId={orderId} referrer={referrer} schedule={data.orderShippingSchedule} />
        </div>
    );
}

const ORDER_SCHEDULE = gql`
query ($orderId: String!) {
    orderShippingSchedule(orderId: $orderId) {
        holidays
        shipping_days
        shipments {
            date
            status
            fad
            lad
            shipment_items {
                item_id
                sku
                name
                qty
            }
        }
    }
}
`;

