import React, { useState } from 'react';
import { useMutation, gql } from "@apollo/client";
import { pluralise } from '../../../Checkout/Sections/helpers';
import { getUtcDate, getIsoDateString } from '../../helpers';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faSpinner } from '@fortawesome/pro-light-svg-icons';
import Shipments from '../Shipments';
import styles from '../style.module.css';

export default function Schedule({orderId, referrer, schedule}) {
    const [openDates, setOpenDates] = useState({});
    const [changesMade, setChangesMade] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState('');
    const [saveScheduleMutation] = useMutation(SAVE_SCHEDULE);
    const cancelDestination = referrer ?? '/';

    if (!Object.keys(openDates).length) {
        const dates = {};
        schedule.shipments.forEach(shipment => {
            if (shipment.status=='Open') dates[shipment.date] = getUtcDate(shipment.date);
        });
        if (Object.keys(dates).length) setOpenDates(dates);
    }

    function changeDate(originalDate, newDate) {
        setChangesMade(true);
        setOpenDates({...openDates, [originalDate]: newDate});
    }

    function SubmitButtonContent() {
        return (saving)
            ? <FontAwesomeIcon icon={faSpinner} spin />
            : <span>Save changes</span>;
    }

    function submitChanges() {
        setError('');
        setSaving(true);
        const originalDates = Object.keys(openDates);
        const payload = originalDates.map(od => {
            return {
                original_date: od, 
                new_date: getIsoDateString(openDates[od])
            }
        });
        saveScheduleMutation({variables: {orderId: orderId, dates: payload}})
            .then(() => {
                setSaving(false);
                if (window.confirm('Changes saved')) window.location.href=cancelDestination;
            })
            .catch(err => {
                setSaving(false);
                setError(err.message);
            })
    }

    return(
        <>
            <h2>Order #{orderId}</h2>
            <h3>Your order will be dispatched in {pluralise(schedule.shipments.length, 'consignment')} as follows:</h3>
            <Shipments schedule={schedule} openDates={openDates} changeDate={changeDate} />
            <div className={styles.cta}>
                <div>
                    <button className="button button--primary button--keyline" onClick={() => window.location.href=cancelDestination}>
                        <FontAwesomeIcon icon={faChevronLeft} size="sm" />&nbsp;Cancel
                    </button>
                </div>
                <div className={styles.submitButton}>
                    <button className="button button--primary" onClick={submitChanges} disabled={!changesMade || saving}><SubmitButtonContent /></button>
                </div>
            </div>
            <div className={styles.error}>{error}</div>
        </>
    );
}

const SAVE_SCHEDULE = gql`
mutation ($orderId: String!, $dates: [OrderScheduleSaveDate]!) {
    orderShippingScheduleSave(
        input: {
            order_id: $orderId
            dates: $dates
        }
    ) {
        message
    }
}
`;

